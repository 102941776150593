<template>
  <container>
    <nav-tabs active="changed"></nav-tabs>

    <b-form inline class="mt-3" @submit.prevent="search">
      <b-form-input
        id="gcn-search"
        placeholder="GCN"
        class="mr-1"
        v-model="searchFields.gcn"
      ></b-form-input>
      <b-form-input
        id="ndc-search"
        placeholder="NDC"
        class="mr-1"
        v-model="searchFields.ndc"
      ></b-form-input>
      <b-form-input
        id="generic-name"
        placeholder="Generic Name"
        class="mr-1"
        v-model="searchFields.generic_name"
      ></b-form-input>
      <b-form-input
        id="strength"
        placeholder="Strength"
        class="mr-1"
        v-model="searchFields.strength"
      ></b-form-input>
      <b-form-select
        id="narx-type"
        v-model="searchFields.narx_type"
        :options="narxTypes"
        class="mr-1"
      ></b-form-select>

      <b-button variant="appriss-light" class="mt-auto mr-1" type="submit"
        >Search</b-button
      >
      <b-button variant="light" class="mt-auto" @click="reset">Clear</b-button>
    </b-form>

    <b-table
      striped
      hover
      :items="gcns"
      :fields="fields"
      class="mt-3"
      :busy="loading"
    >
      <template #cell(actions)="data">
        <router-link
          :to="{ name: 'GcnEdit', params: { id: data.item.gcn_seqno } }"
          >Edit Gcn</router-link
        >
      </template>

      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>

    <b-pagination
      :per-page="perPage"
      :total-rows="totalCount"
      :value="page"
      @input="load($event)"
    ></b-pagination>
  </container>
</template>

<script>
import Container from "@/components/Container.vue";
import NavTabs from "@/components/NavTabs.vue";
import GcnService from "@/services/gcns";
import TokenService from "@/services/token";

import narxTypes from "@/lib/menu/narx_type.js";

export default {
  data() {
    return {
      narxTypes,
      page: 1,
      perPage: 25,
      totalCount: 0,
      gcnService: new GcnService(),
      tokenService: new TokenService(),
      loading: false,
      gcns: [],
      fields: [
        "gcn",
        "generic_name",
        "label_name",
        "narx_type_1",
        "narx_unit_eq_1",
        "narx_active_ingredient_1",
        "strength",
        "drug_schedule",
        "route",
        "actions"
      ],
      searchFields: {
        gcn: "",
        ndc: "",
        generic_name: "",
        strength: "",
        narx_type: ""
      }
    };
  },
  methods: {
    async reset() {
      this.searchFields.gcn = "";
      this.searchFields.ndc = "";
      this.searchFields.generic_name = "";
      this.searchFields.strength = "";
      this.searchFields.narx_type = "";

      await this.load(this.page);
    },
    async search() {
      await this.load(this.page);
    },
    async load(page) {
      this.loading = true;
      let searchFields = Object.keys(this.searchFields)
        .filter(k => this.searchFields[k])
        .reduce((a, k) => ({ ...a, [k]: this.searchFields[k] }), {});
      let response = await this.gcnService.index(
        this.perPage,
        page,
        searchFields
      );
      this.gcns = response.data.gcn_sequences;
      this.page = response.data.meta.current_page;
      this.totalCount = response.data.meta.total_count;
      this.loading = false;
    }
  },
  async beforeMount() {
    await this.tokenService.getToken();
    await this.load(this.page);
  },
  watch: {
    loading(value) {
      if (value) {
        this.$store.commit("showLoading");
      } else {
        this.$store.commit("hideLoading");
      }
    }
  },
  components: { NavTabs, Container }
};
</script>

<style></style>
