<template>
  <b-nav tabs class="appriss-tabs">
    <b-nav-item :active="active === 'master'" :to="{ name: 'GcnMaster' }"
      >GCN Master</b-nav-item
    >
    <b-nav-item :active="active === 'changed'" :to="{ name: 'GcnUnapproved' }"
      >Unapproved GCN</b-nav-item
    >
  </b-nav>
</template>

<script>
export default {
  name: "NavTabs",
  props: ["active"],
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
